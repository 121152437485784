import { FAQs, CONTACT_US, PRIVACY_POLICY, TERMS_AND_CONDITIONS, CAREERS } from 'routes/external'
import { ABOUT, ORDER_STATUS, VIP_TICKETS } from 'routes/paths'
import { Grid, Typography, Link, Icon, Widget } from '~ui'
import styles from './Footer.module.scss'
import SubscribeForm from './subscribe-form'

const { Text } = Typography

const Copyright = () => (
  <Grid gap={4} className={styles.copyright}>
    <Text color="g-gray-1" size="sm">
      Copyright @{new Date().getUTCFullYear()} Granted App, LLC All Rights Reserved.
    </Text>
    <Grid className={styles['copyright-links']} gridAutoFlow="column" gap={8}>
      <Link size="sm" href={PRIVACY_POLICY} target="_blank" rel="noreferrer">
        Privacy policy
      </Link>
      <Text color="g-gray-1" size="sm">
        |
      </Text>
      <Link size="sm" href={TERMS_AND_CONDITIONS} target="_blank" rel="noreferrer">
        Terms of Service
      </Link>
    </Grid>
  </Grid>
)

const TheCompany = () => (
  <Grid className={styles['the-company']} gap={12}>
    <Text color="g-gray-1" size="sm">
      The Company
    </Text>
    <Grid gap={8} justifyContent="start">
      <Link href={VIP_TICKETS} size="sm">
        Vip Tickets
      </Link>
      <Link href={ORDER_STATUS} size="sm">
        Check order status
      </Link>
      <Link href={ABOUT} size="sm">
        Who we are?
      </Link>
      <Link href={FAQs} size="sm" target="_blank" rel="noreferrer">
        FAQs
      </Link>
      <Link href={CAREERS} size="sm" target="_blank" rel="noreferrer">
        Careers
      </Link>
      <Link href={CONTACT_US} size="sm" target="_blank" rel="noreferrer">
        Contact Us
      </Link>
    </Grid>
  </Grid>
)

const TheFun = () => (
  <Grid className={styles['the-fun']} gap={12}>
    <Text color="g-gray-1" size="sm">
      The Fun Stuff
    </Text>
    <Grid className={styles['the-fun-links']}>
      <Link
        href="https://www.instagram.com/granted/"
        uppercase={false}
        target="_blank"
        rel="noreferrer"
      >
        <Text size="sm">@granted</Text>
        <Icon className={styles['icon-link']} name="instagram" width={14} height={14} />
      </Link>
      <Link href="https://www.tiktok.com/@granted.co/" target="_blank" rel="noreferrer">
        <Text size="sm">@granted.co</Text>
        <Icon className={styles['icon-link']} name="tiktok" width={14} height={14} />
      </Link>
      <Link href="https://www.facebook.com/grantedvip" target="_blank" rel="noreferrer">
        <Text size="sm">@grantedvip</Text>
        <Icon className={styles['icon-link']} name="facebook" width={14} height={14} />
      </Link>
      <Link href="https://twitter.com/grantedvip" target="_blank" rel="noreferrer">
        <Text size="sm">@grantedvip</Text>
        <Icon className={styles['icon-link']} name="twitter" width={14} height={14} />
      </Link>
      <Link href="https://www.linkedin.com/company/grantedco" target="_blank" rel="noreferrer">
        <Text size="sm">@grantedco</Text>
        <Icon className={styles['icon-link']} name="linkedin" width={14} height={14} />
      </Link>
    </Grid>
  </Grid>
)

const Subscribe = () => (
  <Grid className={styles['subscribe-form']}>
    <Widget borderColor="purple" glow>
      <Grid className={styles['subscribe-form-heading']}>
        <Text size="lg" color="g-white">
          Subscribe to stay in the loop
        </Text>
      </Grid>
      <SubscribeForm />
    </Widget>
  </Grid>
)

const Footer = () => (
  <footer className={styles.footer}>
    <Grid gap={24} className={styles['footer-content']}>
      <TheCompany />
      <Subscribe />
      <Copyright />
      <TheFun />
    </Grid>
  </footer>
)

export default Footer
