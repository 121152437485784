import classnames from 'classnames'
import { useQuery, gql } from '@apollo/client'
import { useEffect, useState, useCallback } from 'react'

import useUser from 'hooks/useUser'
import { CHECKOUT, LOGIN, ACCOUNT_SETTINGS, LOGOUT, EVENT, TOUR, STAR, VENUE } from 'routes/paths'
import { ALLOWED_USER_TYPES } from 'constants/constant'
import { GetUserActiveShortCartQuery } from 'gql'
import { Grid, Desktop, Link, Typography, Button, Icon, Badge } from '~ui'
import { IconTypes } from '~ui/Icon'
import { useQueryParams } from '~hooks'

import styles from './UserWidget.module.scss'

const { Text } = Typography

interface ILink {
  label: string
  icon: IconTypes
  to: string
}

export const GET_USER_ACTIVE_SHORT_CART = gql`
  query GetUserActiveShortCart {
    findCart {
      pk
      id
    }
  }
`

const links: Array<ILink> = [
  {
    label: 'My Account',
    icon: 'user',
    to: ACCOUNT_SETTINGS,
  },
  {
    label: 'Logout',
    icon: 'logout',
    to: LOGOUT,
  },
]

const UserDropDown = ({ currentUser }) => {
  const [isOpened, setIsOpened] = useState(false)
  const [isHidden, setIsHidden] = useState(true)

  useEffect(() => {
    if (isOpened) setIsHidden(false)
  }, [isOpened])

  return (
    <div
      className={styles.wrapper}
      onMouseEnter={() => setIsOpened(true)}
      onMouseLeave={() => setIsOpened(false)}
      aria-hidden
    >
      <button
        type="button"
        data-test-id="user-widget"
        className={styles.button}
        onFocus={() => setIsOpened(true)}
        onTouchStart={() => setIsOpened((prev) => !prev)}
      >
        <Desktop>
          <Text size="sm">
            {currentUser.firstName} {currentUser.lastName}
          </Text>
        </Desktop>
        <Icon width="1.6em" height="1.6em" name="user" />
      </button>
      <div
        onBlur={() => setIsOpened(false)}
        onFocus={() => setIsOpened(true)}
        className={classnames(styles.body, {
          [styles['body-opened']]: isOpened,
          [styles['body-hidden']]: isHidden,
        })}
        onTransitionEnd={(e) => {
          if (!isOpened && e.target === e.currentTarget) setIsHidden(true)
        }}
      >
        {links.map(({ label, icon, to }) => (
          <span key={to} className={styles.link} data-test-id={label}>
            <Link href={to} tabIndex={isOpened ? 0 : -1}>
              <Icon name={icon} />
              <Text size="sm">{label}</Text>
            </Link>
          </span>
        ))}
        {currentUser.type === 'ADMIN' && (
          <span className={styles.link}>
            <Link tabIndex={isOpened ? 0 : -1} target="_blank" rel="noreferrer" href="/admin-v2">
              <Icon name="gear" />
              <Text size="sm">Admin Dashboard</Text>
            </Link>
          </span>
        )}
      </div>
    </div>
  )
}

export const CheckoutLink = ({ isCart }) => {
  const { push } = useQueryParams()

  const onClick = useCallback(() => {
    push(CHECKOUT)
  }, [push])

  return (
    <Badge dot={isCart} color="pink">
      <button type="button" onClick={onClick} className={styles.button}>
        <Icon name="basket" width="1.6em" height="1.6em" />
      </button>
    </Badge>
  )
}

const Auth = () => {
  const { asPath, pathname, withQueryParams } = useQueryParams()

  const redirect = [TOUR.pathname, EVENT.pathname, VENUE.pathname, STAR.pathname].includes(pathname)
    ? asPath
    : null

  return (
    <Grid gap={24} gridAutoFlow="column" alignItems="center">
      <Link href={withQueryParams(LOGIN, { redirect })}>
        <Button
          className={styles['login-button']}
          variant="primary"
          icon="key"
          iconSize="2em"
          iconGlow
          uppercase
          tabIndex={-1}
        >
          Log In
        </Button>
      </Link>
    </Grid>
  )
}

const UserWidget = () => {
  const [user] = useUser()
  const { data: cartInfo } = useQuery<GetUserActiveShortCartQuery>(GET_USER_ACTIVE_SHORT_CART)

  const isCart = !!cartInfo?.findCart?.id

  return (
    <Grid gap={20} gridAutoFlow="column" alignItems="center">
      {user ? (
        <>
          {ALLOWED_USER_TYPES.includes(user.type) && <UserDropDown currentUser={user} />}
          {!ALLOWED_USER_TYPES.includes(user.type) && !isCart && <Auth />}
          {isCart && <CheckoutLink isCart={isCart} />}
        </>
      ) : (
        <Auth />
      )}
    </Grid>
  )
}

export default UserWidget
