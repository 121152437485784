import classnames from 'classnames'
import { useEffect, useRef, useState, Fragment } from 'react'
import UserWidget from '~shared/UserWidget'
import L from 'next/link'

import { HOME } from 'routes/paths'
import { Grid, Link, Icon, Logo, Button } from '~ui'

import styles from './Sidebar.module.scss'

const MobileDropdown = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef<HTMLDivElement>()

  return (
    <div className={classnames(styles.dropdown, { [styles.dropdownOpen]: isOpen })}>
      <button
        type="button"
        className={classnames(styles.title, styles.link)}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {title} <Icon name="arrow" />
      </button>
      <div className={styles.body} style={{ maxHeight: isOpen ? ref.current.offsetHeight : 0 }}>
        <div className={styles.content} ref={ref}>
          {children}
        </div>
      </div>
    </div>
  )
}

const Sidebar = ({ isOpen, onClose, links }) => {
  const ref = useRef<HTMLDivElement>()

  useEffect(() => {
    if (isOpen) {
      ref.current.style.visibility = 'visible'
    }
  }, [isOpen])

  return (
    <div
      ref={ref}
      className={classnames(styles.wrapper, { [styles.open]: isOpen })}
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose()
      }}
      aria-hidden="true"
      tabIndex={-1}
    >
      <div
        className={styles.sidebar}
        onTransitionEnd={() => {
          if (!isOpen) ref.current.style.visibility = 'hidden'
        }}
      >
        <div className={styles.content}>
          <div className={styles.header}>
            <div style={{ position: 'absolute', left: 0 }}>
              <Button icon="cross" className={styles['close-button']} onClick={onClose} />
            </div>

            <L href={HOME}>
              <a style={{ display: 'flex', margin: '0 auto' }}>
                <Logo />
              </a>
            </L>

            <div style={{ position: 'absolute', right: 0 }}>
              <UserWidget />
            </div>
          </div>

          <Grid>
            {links.map(({ path, label, children }) => (
              <Fragment key={label}>
                {children ? (
                  <MobileDropdown title={label}>
                    {children.map(({ label: l, path: p }) => (
                      <div className={styles.link}>
                        <Link key={l} uppercase href={p}>
                          {l}
                        </Link>
                      </div>
                    ))}
                  </MobileDropdown>
                ) : (
                  <div className={styles.link}>
                    <Link uppercase href={path}>
                      {label}
                    </Link>
                  </div>
                )}
              </Fragment>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
