import { FC, useMemo } from 'react'
import Head from 'next/head'

import { META_IMAGE } from 'constants/constant'
import { getImageUrl } from '~utils'

const getTitle = (title) => (title ? `${title} | Granted` : 'Granted')

type MetaTagsProps = {
  title?: string
  description?: string
  imageSrc?:
    | {
        path: string
        extension: string
      }
    | string
  themeColor?: string
  // eslint-disable-next-line
  schema?: any
  path?: string
}

const ABOUT = `Welcome to a brand new platform designed to give you the VIP access you deserve to some of the most in-demand events and tours the world has to offer.`

const MetaTags: FC<MetaTagsProps> = ({
  title,
  schema,
  imageSrc,
  themeColor = '#000',
  path,
  children,
  ...props
}) => {
  // eslint-disable-next-line no-nested-ternary
  const image = imageSrc
    ? typeof imageSrc === 'string' || imageSrc instanceof String
      ? (imageSrc as string)
      : getImageUrl(imageSrc, 1200, 630)
    : getImageUrl(META_IMAGE, 1200, 630)

  const desc = (props.description || ABOUT).split(' ').splice(0, 25)

  let { description } = props

  if (desc.length === 25) {
    description = `${desc.join(' ')}...`
  }

  const url = useMemo(() => path && new URL(path, 'https://granted.co/'), [path])

  return (
    <Head>
      {/* Primary Meta Tags */}
      <title>{getTitle(title)}</title>
      <meta name="title" content={getTitle(title)} />
      <meta name="description" content={description} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      {url && <meta property="og:url" content={url.href} />}
      <meta property="og:title" content={getTitle(title)} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      <meta property="fb:app_id" content="465019221206319" />

      {/* Twitter  */}
      <meta property="twitter:card" content="summary_large_image" />
      {url && <meta property="twitter:url" content={url.href} />}
      <meta property="twitter:title" content={getTitle(title)} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />

      {/* android */}
      <meta name="theme-color" content={themeColor} />

      {/* windows phones */}
      <meta name="msapplication-navbutton-color" content={themeColor} />

      {/* iOS Safari */}
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content={themeColor} />

      {children}

      {schema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schema),
          }}
        />
      )}
    </Head>
  )
}

export default MetaTags
