import classnames from 'classnames'
import { FC, CSSProperties, Ref } from 'react'

import styles from './Flex.module.scss'

interface FlexProps extends CSSProperties {
  className?: string
  innerRef?: Ref<HTMLDivElement>
}

const Flex: FC<FlexProps> = ({ className, children, innerRef, ...props }) => (
  <div ref={innerRef} className={classnames(className, styles.base)} style={{ ...props }}>
    {children}
  </div>
)

export default Flex
