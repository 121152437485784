import classnames from 'classnames'
import { FC } from 'react'

import styles from './Widget.module.scss'

type ContentWidgetProps = {
  removeBorderRatiusOnMobile?: boolean
  withPadding?: boolean
}

const ContentWidget: FC<ContentWidgetProps> = ({
  withPadding = true,
  removeBorderRatiusOnMobile = true,
  children,
}) => (
  <div
    className={classnames(styles['content-widget'], {
      [styles['content-widget-mobile-border-radius']]: removeBorderRatiusOnMobile,
      [styles['content-widget-with-padding']]: withPadding,
    })}
  >
    {children}
  </div>
)

export default ContentWidget
