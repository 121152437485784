import { useMemo } from 'react'

const useParseJSON = <T extends object>(json: string) => {
  const parsedJSON = useMemo<T>(() => {
    try {
      return JSON.parse(json)
    } catch {
      return undefined
    }
  }, [json])

  return parsedJSON
}

export default useParseJSON
