import HomeHeader from './HomeHeader'
import Header from './Header'
import Main from './Main'
import Footer from './Footer'
import Content from './Content'
import Sidebar from './Sidebar'
import Banner from './Banner'
import Widget from './Content/Widget'

import styles from './Layout.module.scss'

const LayoutWrapper = ({ children }) => <section className={styles.layout}>{children}</section>

const LayoutContent = Content as typeof Content & {
  Widget: typeof Widget
}

const LayoutMain = Main as typeof Main & {
  Content: typeof LayoutContent
  Sidebar: typeof Sidebar
}

const Layout = LayoutWrapper as typeof LayoutWrapper & {
  Header: typeof Header
  HomeHeader: typeof HomeHeader
  Main: typeof LayoutMain
  Footer: typeof Footer
  Banner: typeof Banner
}

Layout.Header = Header
Layout.HomeHeader = HomeHeader
Layout.Main = LayoutMain
Layout.Footer = Footer
Layout.Banner = Banner

Layout.Main.Sidebar = Sidebar
Layout.Main.Content = LayoutContent

Layout.Main.Content.Widget = Widget

export default Layout
