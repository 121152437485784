import { useMemo } from 'react'
import { useRouter } from 'next/router'

import { removeEmptyKeys, isObject, getIdFromSlug } from 'utils'

import * as paths from 'routes/paths'

const SALES_AS_BUY_IT_NOW = 'buy_it_now_option'
const SALES_AS_AUCTION = 'auction'

export type Query = {
  query: string

  slug: string
  salesAs: string

  categoryIds: string | Array<string>
  countries: string | Array<string>
  states: string | Array<string>

  offerId: string

  orderId?: number
  email?: string

  redirect: string

  vip: boolean
  quantity: string
  sort: string
  keywords: string

  auction: string
  bid: string

  reassignCart: string

  showModal: boolean
  follow?: boolean

  experienceId: number

  r?: string
  cityId?: string
  defaultCity?: boolean
  dateRange?: string
}

const initialQuery = {
  categoryIds: [],
  countries: [],
  states: [],
}

const validateQuery = (query: Query) => {
  const queryCopy = { ...query }

  if (query.salesAs && ![SALES_AS_BUY_IT_NOW, SALES_AS_AUCTION].includes(query.salesAs as string)) {
    queryCopy.salesAs = SALES_AS_BUY_IT_NOW
  }

  return queryCopy
}

const withQueryParams = (pathname: string, query) => ({
  pathname,
  ...(query
    ? {
        query: {
          ...removeEmptyKeys(query),
        },
      }
    : {}),
})

const useQueryParams = () => {
  const { query, pathname, push: routerPush, replace: routerReplace, asPath } = useRouter()

  const queryData = useMemo(
    () =>
      validateQuery({
        ...initialQuery,
        ...query,
        defaultCity: query.defaultCity === 'true' || null,
        experienceId:
          query.slug && pathname === paths.EVENT.pathname
            ? +getIdFromSlug(query.slug as string)
            : null,
      } as unknown as Query),
    [query, pathname]
  )

  const push: typeof routerPush = (url, as, options) =>
    routerPush(isObject(url) ? removeEmptyKeys(url) : url, as, options)

  const replace: typeof routerReplace = (url, as, options) =>
    routerReplace(isObject(url) ? removeEmptyKeys(url) : url, as, options)

  return {
    push,
    paths,
    asPath,
    replace,
    pathname,
    withQueryParams,
    query: queryData,
  }
}

export default useQueryParams
