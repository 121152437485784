import { FC, useState, useCallback, ChangeEvent, useEffect, useMemo, Fragment } from 'react'
import { useQuery, NetworkStatus } from '@apollo/client'
import { useDebounce } from 'use-debounce'
import classNames from 'classnames'

import { GrantedSearchQuery, GrantedSearchQueryVariables } from 'gql'
import { Icon, Spinner } from 'components/ui'
import { GRANTED_SEARCH } from 'gql/queries'
import { useDataLayer } from 'hooks'
import { getSearchItemByType } from './search-item'

import styles from './search-input.module.scss'

type SearchType = 'stars' | 'venues' | 'events' | 'tours'

type SearchInputProps = {
  types: Array<SearchType>
  defaultQueryString?: string
  inputPlaceholder: string
  debounceInMs?: number
}

const SearchInput: FC<SearchInputProps> = ({
  types,
  defaultQueryString = '',
  debounceInMs = 200,
  inputPlaceholder,
}) => {
  const [query, setQuery] = useState(defaultQueryString)
  const [debouncedQuery] = useDebounce(query, debounceInMs)

  const [loading, setLoading] = useState(false)

  const { data, networkStatus } = useQuery<GrantedSearchQuery, GrantedSearchQueryVariables>(
    GRANTED_SEARCH,
    {
      variables: {
        query: debouncedQuery,
        types,
      },
      skip: !debouncedQuery,
      onCompleted: () => {
        setLoading(false)
      },
    }
  )

  const { push } = useDataLayer()

  const [formattedData, setFormattedData] = useState({
    stars: [],
    venues: [],
    events: [],
    tours: [],
  })

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)

    if (e.target.value) {
      setLoading(true)
    } else {
      setLoading(false)
      setFormattedData({ stars: [], venues: [], events: [], tours: [] })
    }
  }, [])

  useEffect(() => {
    if (data?.grantedSearch) {
      setFormattedData({
        stars: data.grantedSearch.stars,
        tours: data.grantedSearch.tours,
        events: data.grantedSearch.events,
        venues: data.grantedSearch.venues,
      })
    }
  }, [data?.grantedSearch])

  const [focus, setFocus] = useState(false)

  const handleFocus = useCallback(() => {
    setFocus(true)
  }, [])

  const arrayOfItems = useMemo(() => Object.entries(formattedData), [formattedData])

  const filteredArrayOfItems = useMemo(
    () => arrayOfItems.filter(([, results]) => results?.length),
    [arrayOfItems]
  )

  const noData = useMemo(() => !filteredArrayOfItems.length, [filteredArrayOfItems.length])

  useEffect(() => {
    if (networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables) {
      push({ event: 'search_request', search_query: debouncedQuery })
    }
  }, [networkStatus])

  useEffect(() => {
    if (focus) {
      push({ event: 'search_click' })
    }
  }, [focus])

  return (
    <div
      className={classNames(styles.wrapper, { [styles['wrapper--focus']]: focus })}
      onMouseEnter={() => setFocus(true)}
      onMouseLeave={() => setFocus(false)}
    >
      <div className={styles.background}>
        <div className={styles['input-wrapper']}>
          <div className={styles.icon}>
            {loading ? (
              <Spinner size="18px" borderWidth="2px" />
            ) : (
              <Icon name="loupe" width="20px" height="20px" fill="currentColor" />
            )}
          </div>
          <input
            placeholder={inputPlaceholder}
            className={styles.input}
            onChange={handleChange}
            onFocus={handleFocus}
            value={query}
          />
        </div>

        {!!query && focus && noData && !loading && (
          <div className={styles.overlay}>
            <div className={styles.sections}>
              <div className={styles.section}>
                {getSearchItemByType('search_results_page', null)}
              </div>
            </div>
          </div>
        )}

        {focus && !noData && (
          <div className={styles.overlay}>
            <div className={styles.sections}>
              {filteredArrayOfItems.map(([type, results]) => (
                <div key={type} className={styles.section}>
                  <div className={styles.title}>{type}</div>
                  {results.map((result) => (
                    <Fragment key={`${type}-${result.slug}`}>
                      {getSearchItemByType(type, result)}
                    </Fragment>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SearchInput
