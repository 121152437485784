import classnames from 'classnames'
import L from 'next/link'
import { FC, useState } from 'react'

import { useQueryParams } from 'hooks'
import { HOME, ABOUT, VENUES, AUCTIONS, COMEDY, CONCERTS, VIP_TICKETS } from 'routes/paths'
import { Sidebar, UserWidget } from '~shared'
import { Mobile, Desktop, Menu, Icon, IconButton, Link, Logo } from '~ui'

import styles from './Header.module.scss'

type HeaderProps = {
  id?: string
  fluid?: boolean
  grantedSecureBanner?: boolean
}

export type LinksType = {
  label: string
  path: string
  children?: Array<LinksType>
}

const links: LinksType[] = [
  { label: 'Vip Tickets', path: VIP_TICKETS },
  { label: 'Concerts', path: CONCERTS },
  { label: 'Comedy', path: COMEDY },
  { label: 'Auctions', path: AUCTIONS },
  { label: 'Venues', path: VENUES },
  { label: 'About Us', path: ABOUT },
]

const Nav = () => (
  <nav className={styles.navigation}>
    <ul className={styles.links}>
      {links.map(({ label, path, children }) => {
        if (path === VIP_TICKETS) {
          // marketing team wants to use h1 for vip-tickets for seo optimization
          return (
            <li className={styles.item} key={label}>
              <h1 style={{ all: 'inherit' }}>
                <Link uppercase href={path}>
                  <span>{label}</span>
                </Link>
              </h1>
            </li>
          )
        }

        return (
          <li className={styles.item} key={label}>
            <Link uppercase href={path}>
              <span>{label}</span>
              {children && <Icon name="arrow" fill="currentColor" height="1em" width="1em" />}
            </Link>

            {children && (
              <div className={styles['sub-links']}>
                <Menu
                  items={children.map(({ label: l, path: p }) => ({
                    linkProps: { href: p },
                    label: l,
                    value: l,
                  }))}
                />
              </div>
            )}
          </li>
        )
      })}
    </ul>
  </nav>
)

const Header: FC<HeaderProps> = ({ fluid = true, id, children, grantedSecureBanner }) => {
  const { pathname, paths } = useQueryParams()

  const [isOpen, setIsOpen] = useState(false)

  const onToggle = () => setIsOpen((prev) => !prev)

  return (
    <div
      className={classnames(styles['header-wrapper'], {
        [styles['granted-secure']]: grantedSecureBanner,
      })}
    >
      <Sidebar isOpen={isOpen} onClose={onToggle} links={links} />
      <header
        className={classnames(styles.header, {
          [styles['header-event']]: pathname === paths.EVENT.pathname,
        })}
      >
        <div
          className={classnames(styles['header-inner'], {
            [styles['header-inner-with-max-width']]: fluid,
          })}
        >
          <Mobile>
            <IconButton iconName={isOpen ? 'cross' : 'menu'} onClick={onToggle} />
          </Mobile>

          {id ? (
            <Desktop>
              <L href={HOME}>
                <a style={{ display: 'flex' }}>
                  <Logo />
                </a>
              </L>
            </Desktop>
          ) : (
            <L href={HOME}>
              <a style={{ display: 'flex' }}>
                <Logo />
              </a>
            </L>
          )}

          {children}

          {!children && <Nav />}

          <UserWidget />
        </div>
      </header>
    </div>
  )
}

export default Header
