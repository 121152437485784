import { useCallback } from 'react'
import { object } from 'yup'
import { useFormik } from 'formik'
import { LIST_SENDGRID_NAMES } from 'constants/constant'
import { useEmailSubsribeContactList } from '~hooks'
import { Typography } from '~ui'
import { EMAIL } from 'constants/schema'

import styles from './Footer.module.scss'

const validationSchema = object().shape({
  email: EMAIL(),
})

const initialValues = {
  email: '',
}

const SubscribeForm = () => {
  const [mutate] = useEmailSubsribeContactList()

  const onSubmit = useCallback(async (values, { setStatus }) => {
    const { data } = await mutate({
      variables: {
        listNames: [LIST_SENDGRID_NAMES.SIGN_UP, LIST_SENDGRID_NAMES.general_welcome_series],
        data: { ...values },
      },
    })

    if (data.addOrUpdateContactList.__typename === 'ContactMutationFailure') {
      setStatus(`Something went wrong. (${data.addOrUpdateContactList.errorMessage})`)
      return
    }

    if (data.addOrUpdateContactList.__typename === 'ContactMutationSuccess') {
      setStatus('Thanks for subscribing.')
    }
  }, [])

  const { handleSubmit, handleChange, handleBlur, values, status } = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
  })

  return status ? (
    <div className={styles['subscribe-status']}>
      <Typography.Text color="g-white">{status}</Typography.Text>
    </div>
  ) : (
    <form onSubmit={handleSubmit} className={styles.subscribe}>
      <div className={styles['subscribe-form']}>
        <input
          id="footer-email"
          name="email"
          placeholder="Enter your Email Address"
          type="email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          className={styles['subscribe-input']}
        />
        <button type="submit" className={styles['subscribe-button']}>
          Subscribe
        </button>
      </div>
    </form>
  )
}

export default SubscribeForm
