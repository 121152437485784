import classNames from 'classnames'
import { FC } from 'react'

import styles from './badge.module.scss'

type BadgeProps = { color?: GThemeColorsNew; dot?: boolean }

const Badge: FC<BadgeProps> = ({ children, color, dot }) => (
  <div className={styles.wrapper}>
    {children}
    {dot && <div className={classNames(styles.dot, { [styles[color]]: color })} />}
  </div>
)

export default Badge
