import classnames from 'classnames'
import { FC } from 'react'

import styles from './Spinner.module.scss'

type SpinnerProps = {
  center?: boolean
  withBackground?: boolean
  absolute?: boolean
  borderWidth?: string
  size?: string
  color?: GThemeColorsNew
}

const Spinner: FC<SpinnerProps> = ({
  center,
  withBackground,
  absolute,
  borderWidth,
  size,
  color = 'white',
}) => (
  <div
    className={classnames(styles.wrapper, {
      [styles[`wrapper-center`]]: center,
      [styles[`wrapper-background`]]: withBackground,
      [styles[`wrapper-absolute`]]: absolute,
    })}
  >
    <div
      className={classnames(styles.loader, styles[color])}
      style={{ borderWidth, width: size, height: size }}
    />
  </div>
)

export default Spinner
