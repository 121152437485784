import { useQuery } from '@apollo/client'

import { GrantedWebSettingsQuery, GrantedWebSettingsQueryVariables } from 'gql'
import { GRANTED_WEB_SETTINGS } from 'gql/queries'
import { useParseJSON } from 'hooks'

const Banner = () => {
  const { data } = useQuery<GrantedWebSettingsQuery, GrantedWebSettingsQueryVariables>(
    GRANTED_WEB_SETTINGS,
    {
      variables: { name: 'banner' },
    }
  )

  const bannerData = useParseJSON<{ text: string; link: string }>(
    data?.grantedWebSettings.edges[0]?.node.settings
  )

  if (!bannerData) return null

  return (
    <a
      className="text-center block text-sm bg-g-elevated border-b border-g-dividers px-4 py-4 transition-all hover:glow-text-primary"
      href={bannerData.link}
      rel="noreferrer"
      target="_blank"
    >
      {bannerData.text}
    </a>
  )
}

export default Banner
