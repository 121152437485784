import { Children, FC, ReactNode } from 'react'
import classnames from 'classnames'
import { Flex } from '~ui'
import styles from './Widget.module.scss'

type WidgetProps = {
  backgroundColor?: GThemeColorsNew
  borderColor?: GThemeColorsNew
  borderRadius?: GThemeBorderRadiuses
  actions?: Array<ReactNode>
  glow?: boolean
  className?: string
}

const Widget: FC<WidgetProps> = ({
  children,
  borderRadius = 'md',
  backgroundColor,
  borderColor,
  actions,
  glow,
  className,
}) => (
  <div
    className={classnames(
      styles.widget,
      styles[`bc${borderColor}`],
      styles[backgroundColor],
      styles[borderRadius],
      {
        [styles.glow]: glow,
        [className]: className,
      }
    )}
  >
    {children}
    {actions && (
      <Flex className={styles[classnames('widget-buttons-container')]}>
        {Children.map(actions, (action) => (
          <Flex className={styles['widget-button-wrapper']}>{action}</Flex>
        ))}
      </Flex>
    )}
  </div>
)

export default Widget
