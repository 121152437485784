import Link from 'next/link'
import { FC, useMemo } from 'react'
import { format } from 'date-fns'

import { EVENT, STAR, TOUR, VENUE, SEARCH_RESULTS } from 'routes/paths'
import { ALL_STATES } from 'constants/addresses'
import { EventSearchResultNode } from 'gql'
import { getImageUrl } from 'utils'

import styles from './search-item.module.scss'

type SearchItemWrapperProps = {
  href: string
}

const SearchItemWrapper: FC<SearchItemWrapperProps> = ({ children, href }) => (
  <Link href={href}>
    <a className={styles.item}>{children}</a>
  </Link>
)

type EventSearchItemProps = EventSearchResultNode

type DescriptionProps = {
  subtitle?: string
  title: string
}

const Description: FC<DescriptionProps> = ({ title, subtitle }) => (
  <div className={styles.description}>
    <span className={styles.title}>{title}</span>
    {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
  </div>
)

const EventSearchItem: FC<EventSearchItemProps> = ({
  eventStartDateLocal,
  city,
  state,
  slug,
  title,
}) => {
  const time = useMemo(() => format(new Date(eventStartDateLocal), 'h:mma'), [eventStartDateLocal])
  const month = useMemo(() => format(new Date(eventStartDateLocal), 'MMM'), [eventStartDateLocal])
  const day = useMemo(() => format(new Date(eventStartDateLocal), 'dd'), [eventStartDateLocal])

  const shortState = useMemo(
    () =>
      state
        ? ALL_STATES.find(({ label }) => label.toLowerCase() === state.toLowerCase())?.value
        : '',
    [state]
  )

  return (
    <SearchItemWrapper href={EVENT(slug)}>
      <div className={styles.calendar}>
        <span className={styles.month}>{month}</span>
        <span className={styles.day}>{day}</span>
      </div>
      <Description
        subtitle={`${time.toLowerCase()} · ${city} ${shortState || state}`}
        title={title}
      />
    </SearchItemWrapper>
  )
}

export type DefaultSearchItemProps = {
  imagePath?: string
}

export const DefaultSearchItem: FC<
  SearchItemWrapperProps & DefaultSearchItemProps & DescriptionProps
> = ({ imagePath, href, title, subtitle }) => (
  <SearchItemWrapper href={href}>
    {imagePath && (
      <img
        src={getImageUrl(imagePath, 100, 100)}
        className={styles.image}
        alt={title}
        height={36}
        width={36}
      />
    )}

    <Description title={title} subtitle={subtitle} />
  </SearchItemWrapper>
)

export const getSearchItemByType = (type: string, props) => {
  switch (type) {
    case 'search_results_page':
      return <DefaultSearchItem href={SEARCH_RESULTS} title="Continue your search" />

    case 'events':
      return <EventSearchItem {...(props as EventSearchItemProps)} />

    case 'stars':
      return (
        <DefaultSearchItem
          imagePath={props.imagePath}
          href={STAR(props.slug)}
          title={props.fullName}
        />
      )

    case 'venues':
      return (
        <DefaultSearchItem
          imagePath={props.imagePath}
          href={VENUE(props.slug)}
          title={props.name}
        />
      )

    case 'tours':
      return (
        <DefaultSearchItem
          imagePath={props.imagePath}
          href={TOUR(props.slug)}
          title={props.title}
        />
      )

    default:
      return null
  }
}

export default SearchItemWrapper
