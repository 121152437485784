import classnames from 'classnames'
import Link, { LinkProps } from 'next/link'
import { FC } from 'react'

import Icon, { IconTypes } from '~ui/Icon'

import styles from './Menu.module.scss'

type Value = string | number

interface Item {
  linkProps?: LinkProps
  label: string
  onClick?: () => void
  icon?: IconTypes
  disabled?: boolean
  value: Value
}

export interface MenuProps {
  items: Item[]
  onClick?: (value: Value) => void
}

const Menu: FC<MenuProps> = ({ items, onClick: _onClick }) => (
  <ul className={styles.menu}>
    {items.map(({ label, onClick, icon, disabled, linkProps, value }) => (
      <li
        aria-hidden
        tabIndex={-1}
        onClick={() => {
          onClick?.()
          _onClick?.(value)
        }}
        className={classnames(styles.item, { [styles.disabled]: disabled })}
        key={value || label}
      >
        <Icon name={icon} glow={false} fill="currentColor" width="1em" height="1em" />
        {linkProps ? (
          <Link {...linkProps}>
            <a>{label}</a>
          </Link>
        ) : (
          label
        )}
      </li>
    ))}
  </ul>
)

export default Menu
